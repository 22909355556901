import { useCallback, useContext, useEffect, useState } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import api from '../api/api';
import { API_HOST_URL } from '../api/api.const';
import { AuthContext } from '../screens/auth/auth.utils';
import { isWebMobile } from './device';
import throttle from 'lodash/throttle';

export interface UseFetchOpts {
  skip?: boolean;
  publicEndpoint?: boolean;
}

export const useFetch = <T>(url: string, opts: UseFetchOpts = {}) => {
  const { skip, publicEndpoint } = opts || {};
  const { webAuth } = useContext(AuthContext);

  const [status, setStatus] = useState<'loading' | 'failed' | 'completed' | 'skipped'>(
    skip ? 'skipped' : 'loading',
  );
  const [data, setData] = useState<T>();

  useEffect(() => {
    if ((!webAuth && !publicEndpoint) || skip) {
      return;
    }

    const fetchData = async () => {
      try {
        const [response] = await api(
          `${API_HOST_URL}/${url}`,
          { method: 'GET' },
          { ...(webAuth?.accessToken && { Authorization: `Bearer ${webAuth.accessToken}` }) },
        );

        setData(response.data as T);
        setStatus('completed');
      } catch (e) {
        console.error(e);
        setStatus('failed');
      }
    };

    fetchData();
  }, [url, setStatus, webAuth, skip, publicEndpoint]);

  const refetch = useCallback(async () => {
    if ((!webAuth && !publicEndpoint) || skip) {
      return;
    }

    const [response] = await api(
      `${API_HOST_URL}/${url}`,
      { method: 'GET' },
      { ...(webAuth?.accessToken && { Authorization: `Bearer ${webAuth.accessToken}` }) },
    );

    setData(response.data as T);
  }, [webAuth, publicEndpoint, skip, url]);

  if (!skip && status === 'skipped') {
    refetch();
  }

  return {
    loading: status === 'loading',
    data: status === 'completed' ? data : undefined,
    skipped: status === 'skipped',
    refetch,
  };
};

export const useIsWebMobile = () => {
  const dimensions = useWindowDimensions();

  return Platform.OS === 'web' && dimensions.width < 768;
};

export const useIsMobile = () => {
  const dimensions = useWindowDimensions();

  if (Platform.OS === 'web') {
    return (
      isWebMobile(navigator.userAgent || navigator.vendor || (window as any).opera) ||
      dimensions.width < 768
    );
  }

  return true;
};

export const useThrottle = <T extends (..._args: any) => any>(func: T, time = 100, deps = []) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(throttle(func, time, { leading: true, trailing: true }), deps);
};
