import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import type { SourceType } from './ActiveStream.utils';
import ManageChannels from './ManageChannels/ManageChannels';

import Source from './Source/Source';
import Status from './Status/Status';
import Tab from './Tab/Tab';
import { BusinessUserSwitcher } from '#/components/BusinessUserSwitcher/BusinessUserSwitcher';
import { useAppRoute } from '#/navigation/navigation.ref';
import { useBusinessUserTokenRequest, useMeQuery } from '#/api/api.portal';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import socket from '#/socket/socket';
import { AuthContext, useAuthStore } from '#/screens/auth/auth.utils';

const ActiveStreamScreen: React.FC<{}> = ({}) => {
  const route = useAppRoute<'ActiveStream'>();
  const { data: profile } = useMeQuery();
  const { webAuth } = useContext(AuthContext);
  const [request] = useBusinessUserTokenRequest(webAuth?.admin);
  const { selectedUserId, selectedUserLogin } = useSelectedBusinessUserStore();
  const [currentSource, setCurrentSource] = useState<SourceType>(
    (route.params?.source || 'app').toUpperCase() as SourceType,
  );

  useEffect(() => {
    if (!selectedUserId || !profile?.userId) {
      return;
    }

    if (selectedUserId === webAuth?.userId) {
      socket.connectWithToken('');
    } else if (socket.currentlyConnectedUserId !== selectedUserId) {
      request(selectedUserId, selectedUserLogin)
        .then(data => data.data.access_token)
        .then(token => socket.connectWithToken(token, selectedUserId));
    }
  }, [selectedUserId, profile?.userId]);

  return (
    <PageWrapper>
      {selectedUserId && <BusinessUserSwitcher />}
      <Tab title="How to stream with" defaultOpen={route.params?.tab === 'source'}>
        <Source source={currentSource} handleSourceChange={setCurrentSource} />
      </Tab>
      <Tab title="Status" defaultOpen>
        <Status source={currentSource} />
      </Tab>
      <Tab title="Restreaming destinations" defaultOpen>
        <ManageChannels />
      </Tab>
    </PageWrapper>
  );
};

export default ActiveStreamScreen;
