import HubConnect from '#/socket/socket';
import { createZustandStore } from '#/utils/zustand';

export type ChatsAuthor = { Id: string; Name: string; Self: boolean; target?: string };
export type ChatsMessage = {
  Msg: string;
  Time: Date;
  AuthorId: string;
  Tip: number;
  Type: ChatsMessageType;

  // custom
  target: string;
};

export enum ChatsMessageType {
  Message = 0,
  Tip = 1,
}

type PlatformChat = {
  authors: Record<string, ChatsAuthor>;
  messages: ChatsMessage[];
  unreadMessages: number;
  unseenTips: number;
};

export const getInitialPlatformChat = (): PlatformChat => ({
  authors: {},
  messages: [],
  unreadMessages: 0,
  unseenTips: 0,
});

export type ChatsData = Record<string, PlatformChat>;

export type NewChatsEvent = {
  Targets: Array<{ TargetId: string; Authors: ChatsAuthor[]; Messages: ChatsMessage[] }>;
};

interface Store {
  chatsData: ChatsData;
}

export const useChatMessagesStore = createZustandStore(
  'ChatMessages',
  {
    chatsData: {},
  } as Store,
  (set, get) => ({
    sendChatMessage(chatId: string, message: string) {
      HubConnect.sendChatMessage(chatId, message);
    },

    clearUnreadMessages(targetId: string) {
      set(state => {
        if (state.chatsData[targetId]) {
          state.chatsData[targetId].unreadMessages = 0;
          state.chatsData[targetId].unseenTips = 0;
        }
      });
    },

    clearAllUnreadMessages() {
      set(state => {
        Object.keys(state.chatsData).forEach(targetId => {
          state.chatsData[targetId].unreadMessages = 0;
          state.chatsData[targetId].unseenTips = 0;
        });
      });
    },

    onReceiveChatMessages(message: NewChatsEvent) {
      set(state => {
        message.Targets.forEach(target => {
          if (!state.chatsData[target.TargetId]) {
            state.chatsData[target.TargetId] = getInitialPlatformChat();
          }

          const chat = state.chatsData[target.TargetId];

          const lastMessages = chat.messages.slice(-10);

          target.Messages.forEach(message => {
            const exisingMessage = lastMessages.find(
              m => m.Msg === message.Msg && m.Time.getTime() === message.Time.getTime(),
            );

            if (!exisingMessage) {
              chat.messages.push(message);

              if (message.Tip) {
                chat.unseenTips++;
              } else {
                chat.unreadMessages++;
              }
            }
          });

          target.Authors.forEach(author => {
            chat.authors[author.Id] = author;
          });
        });
      });
    },
  }),
);
