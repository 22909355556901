import React, { useContext, useState } from 'react';
import { Text, View } from 'react-native';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import mixins from '../../../app/styles';
import ChartIcon from '../../../assets/icons/chart-box.svg';
import SourceIcon from '../../../assets/icons/switch-camera.svg';
import Button from '../../../components/Button/Button';
import IndicatorsRow from '../../../components/IndicatorsRow/IndicatorsRow';
import StreamSourceList from '../../../components/StreamSourceList';
import { SocketContext } from '../../../socket/socket.provider';
import type { SourceType } from '../ActiveStream.utils';
import styles, { chartMargins } from './Status.styles';
import TabButton from './TabButton';
import UserIcon from '../../../assets/icons/account-box-outline.svg';
import { DeviceState, IndicatorState } from '../../../socket/document.type';
import { useIsWebMobile } from '../../../utils';
import WebRTCPreview from '../../../components/WebRTCPreview';
import { AuthContext } from '../../auth/auth.utils';
import { useAppNavigation } from '#/navigation/navigation.ref';
import Tooltip from '#/components/Tooltip';
import AlertIcon from '#/assets/icons/alert.svg';
import { getWebRtcOutgest } from '#/utils/serverData';

interface Props {
  source: SourceType;
}

const Status: React.FC<Props> = () => {
  const isWebMobile = useIsWebMobile();
  const navigation = useAppNavigation();
  const { isAccountActive } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState<'CHART' | 'SOURCE' | 'PREVIEW'>('CHART');
  const { serverData, commonData, sendPatch } = useContext(SocketContext);
  const { bitrateHistory, currentDeviceType, selectedSceneOwner } = commonData;
  const previewUrl = getWebRtcOutgest(serverData);

  const toggleStream = () =>
    sendPatch(
      ['Settings', 'StreamingToCloudStarted'],
      !serverData?.Settings?.StreamingToCloudStarted,
    );

  const toggleRecording = () =>
    sendPatch(['Settings', 'IsRecordingRequested'], !serverData?.Settings?.IsRecordingRequested);

  const isRecording = serverData?.Settings?.IsRecordingRequested;
  const isStreaming = serverData?.Settings?.StreamingToCloudStarted;
  const isExceedingLimit =
    serverData?.Devices['ext']?.KPIs?.CloudOut?.State === IndicatorState.ExceededBitrate;
  const bitrateWarning = serverData?.Settings?.BitrateWarning;

  const currentDevice = serverData.Devices[selectedSceneOwner];

  if (!currentDevice || !currentDevice.State || currentDevice.State === DeviceState.Offline) {
    return <Text style={styles.textRed}>Offline</Text>;
  }

  const error = isExceedingLimit && (
    <Text style={styles.errorMsg}>
      The bitrate exceeds limit for your current subscription plan!
    </Text>
  );

  return (
    <View>
      <View style={styles.tabs}>
        <View style={styles.buttonWrapper}>
          <TabButton
            active={activeTab === 'CHART'}
            icon={ChartIcon}
            onPress={() => setActiveTab('CHART')}
          />
          {!isWebMobile && (
            <TabButton
              active={activeTab === 'PREVIEW'}
              icon={UserIcon}
              onPress={() => setActiveTab('PREVIEW')}
            />
          )}
          <TabButton
            active={activeTab === 'SOURCE'}
            icon={SourceIcon}
            onPress={() => setActiveTab('SOURCE')}
          />
        </View>
        {activeTab === 'CHART' && (
          <View style={styles.chartWrapper}>
            {error}
            <View style={styles.indicationsContainer}>
              <IndicatorsRow isPortrait />
            </View>
            {navigation.isFocused() && (
              <ResponsiveContainer width="100%" height="100%" minWidth={100} minHeight={100}>
                <AreaChart
                  data={bitrateHistory.map((b, i) => ({
                    bitrate: b,
                    time: i < 120 ? '2m ago' : i < 240 ? '1m ago' : 'now',
                  }))}
                  margin={chartMargins}
                  width={100}
                  height={100}
                >
                  <XAxis dataKey="time" interval={119} />
                  <YAxis />
                  <Area
                    type="monotone"
                    dataKey="bitrate"
                    fillOpacity={0.1}
                    isAnimationActive={false}
                    strokeWidth={3}
                    stroke={mixins.color.green}
                    fill={mixins.color.green}
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </View>
        )}
        {activeTab === 'SOURCE' && (
          <View style={styles.sourcesWrapper}>
            <StreamSourceList showHeader={false} />
          </View>
        )}
        {activeTab === 'PREVIEW' && previewUrl && (
          <View style={styles.previewWrapper}>
            {error}
            <View style={styles.indicationsContainer}>
              <IndicatorsRow isPortrait />
            </View>
            <WebRTCPreview
              url={previewUrl.Data.Output}
              onPlaying={() => {}}
              isStreaming={commonData.KPIs?.CloudOut.State !== IndicatorState.Disabled}
            />
          </View>
        )}
      </View>
      <View style={styles.bitrateWarningWrapper}>
        {bitrateWarning && (
          <Tooltip text={bitrateWarning}>
            <>
              <AlertIcon
                fill={mixins.color.hotOrange}
                width={20}
                style={mixins.styles.margin.rightI3}
              />
              <Text style={styles.bitrateWarning}>Bitrate doesn't match the resolution/FPS</Text>
            </>
          </Tooltip>
        )}
      </View>
      <View style={styles.controls}>
        {currentDeviceType !== 'ext' && (
          <Button
            disabled={!isAccountActive()}
            style={!isStreaming && styles.buttonInactive}
            onPress={toggleStream}
          >
            Stream to cloud
          </Button>
        )}
        {currentDeviceType === 'win' && (
          <Button
            style={[mixins.styles.margin.leftI6, !isRecording && styles.buttonInactive]}
            onPress={toggleRecording}
          >
            Record
          </Button>
        )}
      </View>
    </View>
  );
};

export default Status;
