import { Channel, ChannelState, ChatState, Device, Document } from '#/socket/document.type';
import { SendPatchAction } from '#/socket/socket.type';
import { getDeviceId, getRandomId } from '#/utils';

// CHANNELS

export const updateChannelAction = (
  channelId: string,
  channelData: Partial<Channel>,
): SendPatchAction => ({
  path: ['Channels', channelId],
  value: channelData,
});

export const deleteChannelAction = (channelId: string): SendPatchAction => ({
  path: ['Channels', channelId],
  op: 'remove',
});

export const updateChannelFieldAction = (
  channelId: string,
  fieldName: keyof Channel,
  value: any,
): SendPatchAction => ({
  path: ['Channels', channelId, fieldName],
  value,
});

export const addNewChannelAction = (
  data: Partial<Channel>,
  id = getRandomId(),
): SendPatchAction => ({
  path: ['Channels', id],
  value: {
    ...data,
    IsOn: false,
    State: ChannelState.Idle,
    TargetId: data.TargetId === 'custom' ? null : data.TargetId,
  },
});

// UPDATE SETTINGS

export const updateSettingAction = (
  settingName: keyof Document['Settings'],
  value: any,
): SendPatchAction => ({
  path: ['Settings', settingName],
  value,
});

export const updateStreamingToCloudStartedAction = (value: boolean) =>
  updateSettingAction('StreamingToCloudStarted', value);

export const updateSelectedSceneAction = (sceneId: string) =>
  updateSettingAction('SelectedScene', sceneId);

// DEVICES

export const updateCurrentDeviceFieldAction = (
  field: keyof Device,
  value: any,
): SendPatchAction => ({
  path: ['Devices', getDeviceId(), field],
  value,
});

export const updateCurrentDevicePreviewSourcesAction = (value: boolean): SendPatchAction =>
  updateCurrentDeviceFieldAction('PreviewSources', value);

export const updateCurrentDeviceVideoInputFieldAction = (
  videoInput: string,
  field: string,
  value: any,
): SendPatchAction => ({
  path: ['Devices', getDeviceId(), 'VideoInputs', videoInput, field],
  value,
});

// PLATFORMS

export const savePlatformBrowserTokenAuthAction = (
  targetId: string,
  KeyValues: Record<string, string>,
): SendPatchAction => ({
  path: ['Platforms', 'Platforms', targetId, 'BrowserTokenAuthentication'],
  value: { KeyValues },
  outcome: false,
});

export const initPlatformBrowserTokenAuthAction = (targetId: string): SendPatchAction => ({
  path: ['Platforms', 'Platforms', targetId, 'BrowserTokenAuthentication'],
  value: { KeyValues: {} },
  outcome: true,
});

export const initPlatformChat = (TargetId: string): SendPatchAction => ({
  path: ['Platforms', 'Chats', getRandomId()],
  value: { TargetId },
});

export const reconnectPlatformChatAction = (chatId: string): SendPatchAction => ({
  path: ['Platforms', 'Chats', chatId, 'State'],
  value: ChatState.Connected,
});

export const closeChatAction = (chatId: string): SendPatchAction => ({
  path: ['Platforms', 'Chats', chatId],
  op: 'remove',
});
