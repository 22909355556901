import React, { useContext, useEffect } from 'react';
import { View, Text, ScrollView, Pressable } from 'react-native';
import styles from './StreamSourceList.styles';
import { SocketContext } from '../../socket/socket.provider';
import { getDeviceId } from '../../utils';
import { Device, DeviceState, DeviceType } from '../../socket/document.type';
import { getEmptyLayerWithDevice, getInputSource } from '../../helpers/defaultData';
import { MobileBackHeader } from '#/components/MobileBackHeader/MobileBackHeader';
import { useManageBoxControls } from '#/screens/stream/manageBox/utils';
import {
  updateCurrentDevicePreviewSourcesAction,
  updateSelectedSceneAction,
} from '#/socket/socket.actions';

interface Props {
  handleSourceChanged?: () => void;
  showHeader?: boolean;
}

const StreamSourceList = ({ handleSourceChanged, showHeader = true }: Props) => {
  const {
    serverData,
    sendPatch,
    commonData: { selectedScene },
    getCameraLayerForScene,
  } = useContext(SocketContext);
  const devices = Object.entries(serverData.Devices)
    .filter(([id, d]) => d)
    .map(([id, d]) => ({ ...d, deviceId: id }));

  const externalDevice = devices.find(({ Type }) => Type === 'ext');
  const desktopDevice =
    devices.find(d => d.Type === 'win' && d.State === DeviceState.Online) ||
    devices.find(d => d.Type === 'win');
  const currentVideoDevice = devices.find(d => d.deviceId === getDeviceId());
  const toggleSources = useManageBoxControls(s => s.toggleSources);

  useEffect(() => {
    if (serverData?.Devices[getDeviceId()]) {
      sendPatch(updateCurrentDevicePreviewSourcesAction(true));
      return () => {
        sendPatch(updateCurrentDevicePreviewSourcesAction(false));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendPatch]);

  const currentVideoInputDevice = (deviceId: string, cameraId?: string): boolean => {
    const [deviceSceneId] =
      Object.entries(serverData.Scenes).find(([_id, scene]) => scene.Owner === deviceId) ?? [];

    if (!deviceSceneId || selectedScene !== deviceSceneId) {
      return false;
    }

    if (!cameraId) {
      return true;
    }

    const selectedCameraLayer = getCameraLayerForScene(deviceSceneId);

    if (!selectedCameraLayer) {
      return false;
    }

    return (
      serverData.Scenes[deviceSceneId].Items[selectedCameraLayer].Source.Device?.DeviceName
        .DeviceId === cameraId
    );
  };

  const handleChangeCamera = (deviceId: string, cameraId?: string) => {
    const [deviceSceneId] =
      Object.entries(serverData.Scenes).find(([_id, scene]) => scene.Owner === deviceId) ?? [];

    if (!deviceSceneId) {
      return;
    }

    const selectedCameraLayer = getCameraLayerForScene(deviceSceneId);

    if (selectedScene !== deviceSceneId) {
      // TODO: Fixme, without delay app might not recognize change
      setTimeout(() => sendPatch(updateSelectedSceneAction(deviceSceneId)), 100);
    }

    if (cameraId) {
      const device = {
        DeviceId: cameraId,
        Name: serverData?.Devices[deviceId]?.VideoInputs[cameraId].Name,
      };

      if (selectedCameraLayer !== undefined) {
        sendPatch(
          ['Scenes', `${deviceSceneId}`, 'Items', `${selectedCameraLayer}`, 'Source'],
          getInputSource(device),
          false,
        );
      } else {
        if (serverData.Scenes[deviceSceneId].Items) {
          const numOfLayer =
            Math.max(...Object.keys(serverData.Scenes[deviceSceneId].Items).map(l => Number(l))) +
            1;

          sendPatch(
            ['Scenes', `${deviceSceneId}`, 'Items', `${numOfLayer}`],
            getEmptyLayerWithDevice(device),
          );
        } else {
          sendPatch(
            ['Scenes', `${deviceSceneId}`, 'Items'],
            { '0': getEmptyLayerWithDevice(device) },
            false,
          );
        }
      }
    }

    handleSourceChanged?.();
  };

  const renderDevice = ({ device, deviceId }: { device: Device; deviceId: string }) => {
    const nameMap: Record<DeviceType, any> = {
      ios: 'Mobile',
      and: 'Mobile',
      win: 'Desktop',
      ext: 'External encoder',
      web: '',
    };

    if (Object.entries(device.VideoInputs).length === 0 && device.Type !== 'ext') {
      return null;
    }

    return (
      <View style={styles.device} key={deviceId}>
        <Text style={styles.deviceName}>{nameMap[device.Type]}</Text>
        <View style={styles.deviceDetails}>
          {device.Type === 'ext' && device.State === DeviceState.Online && (
            <Pressable
              onPress={() => handleChangeCamera(deviceId)}
              style={[
                styles.deviceCamera,
                currentVideoInputDevice(deviceId) && styles.deviceCameraActive,
              ]}
            >
              <Text style={styles.deviceCameraName}>Streaming </Text>
              <Text
                style={[
                  styles.deviceBitrate,
                  currentVideoInputDevice(deviceId) && styles.deviceBitrateActive,
                ]}
              >
                {device.KPIs?.CloudOut?.Bitrate} Kb/s
              </Text>
            </Pressable>
          )}
          {device.Type === 'ext' && device.State !== DeviceState.Online && (
            <Text style={styles.deviceStatus}>No stream started</Text>
          )}
          {device.Type === 'win' && device.State !== DeviceState.Online && (
            <Text style={styles.deviceStatus}>
              Run Streamster Desktop app to access your web cameras and other Sources
            </Text>
          )}
          {device.State === DeviceState.Online &&
            Object.entries(device.VideoInputs).map(([inputDeviceId, videoInput]) => (
              <Pressable
                key={inputDeviceId}
                onPress={() => handleChangeCamera(deviceId, inputDeviceId)}
                style={[
                  styles.deviceCamera,
                  currentVideoInputDevice(deviceId, inputDeviceId) && styles.deviceCameraActive,
                ]}
              >
                <Text style={styles.deviceCameraName}>{videoInput.Name}</Text>
              </Pressable>
            ))}
        </View>
      </View>
    );
  };

  const primaryDevices = [currentVideoDevice, externalDevice, desktopDevice];
  const secondaryDevices = devices.filter(
    d => d.State !== undefined && !primaryDevices.find(pd => pd?.deviceId === d.deviceId),
  );

  const deviceList = primaryDevices.concat(secondaryDevices);

  return (
    <>
      {showHeader && <MobileBackHeader title="Sources" onBackPressed={toggleSources} />}
      <ScrollView style={styles.scrollView}>
        <View style={styles.deviceList}>
          {deviceList.map(d => d && renderDevice({ device: d, deviceId: d.deviceId }))}
        </View>
      </ScrollView>
    </>
  );
};

export default StreamSourceList;
