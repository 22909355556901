import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  scrollView: {
    padding: 10,
  },
  header: {
    color: mixins.color.whiteText,
    fontSize: mixins.font.modalFont,
    marginBottom: mixins.indent.i2,
    textTransform: 'uppercase',
  },
  deviceList: { gap: 10 },
  offlineDeviceText: {
    color: mixins.color.whiteText,
    backgroundColor: '#15161a',
    padding: 8,
    flexGrow: 1,
    borderRadius: 5,
  },
  device: {
    flexDirection: 'row',
    gap: 10,
    paddingHorizontal: 10,
    overflow: 'hidden',
  },
  deviceName: { color: mixins.color.grayLightest, width: 110, textAlign: 'right', lineHeight: 35 },
  deviceDetails: { flexDirection: 'row', gap: 5, flexWrap: 'wrap', flex: 1 },
  deviceStatus: {
    color: mixins.color.whiteText,
    backgroundColor: '#15161a',
    padding: 8,
    paddingHorizontal: 16,
    flexGrow: 1,
    borderRadius: 5,
  },
  deviceCamera: {
    backgroundColor: '#282930',
    padding: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    flexGrow: 1,
    flexDirection: 'row',
  },
  deviceCameraActive: {
    backgroundColor: mixins.color.blue,
  },
  deviceCameraNameCentered: { color: mixins.color.whiteText, textAlign: 'center' },
  deviceCameraName: { color: mixins.color.whiteText },
  deviceBitrate: {
    color: mixins.color.grayDarker,
    marginLeft: 'auto',
  },
  deviceBitrateActive: {
    color: mixins.color.whiteText,
  },
});

export default styles;
